import {Injectable, OnDestroy} from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {FormGroup} from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    public filterForm: FormGroup = new FormGroup('');
    public currentPage: number = 1;
    public pageSize: number = 15;
    public totalPages: number;
    public total: number;
    public filterCollapsed: boolean = true;
    public filterUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public route: ActivatedRoute,
        public router: Router
    ) {
    }

    public setParams(queryParams: any[]): void
    {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge'
        });
    }

    public buildQueryParams(rawQueryParams: any[]): string {
        let params = new HttpParams();
        if (rawQueryParams) {
            rawQueryParams.forEach((param: any) => {
                params = params.set(param.name, param.value);
            });
        }

        return params.toString();
    }
}
